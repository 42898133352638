import React from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import Joi from "joi-browser";
import Form from "./common/form";
import Captcha from "./common/captcha";
import ResendButton from "./common/resendCode";
import logo from "../images/Logo.png";

class ForgotPassword2 extends Form {
  state = {
    data: { code: "" },
    errors: {},
    errorCaptcha: "",
    buttonStatus: false,
  };

  schema = {
    code: Joi.label("Code"),
  };

  componentDidMount() {
    loadCaptchaEnginge(6, "white", "gray");
  }

  buttonStatus = (status) => {
    this.setState({ buttonStatus: status });
    const myInput = document.getElementById("code");
    if (!status) {
      myInput.disabled = true;
    } else {
      myInput.disabled = false;
    }
  };

  handelResendCode = async () => {
    // try {
    //   const body = {
    //     phoneNumber: this.props.phoneNumber,
    //     password: this.props.password,
    //   };
    //   const result = await auth.login(body);
    // } catch (ex) {
    //   if (ex["response"]["status"] === 400) {
    //     console.log("bye");
    //     // toast.error("Authentication has failed");
    //     // window.location = "/register";
    //   }
    // }
  };

  checkCaptcha = () => {
    let user_captcha = document.getElementById("user_captcha_input").value;

    //check captcha
    if (validateCaptcha(user_captcha) === true) {
      this.setState({ errorCaptcha: "" });
      document.getElementById("user_captcha_input").value = "";
    } else {
      this.setState({ errorCaptcha: "Captcha Does Not Match" });
      document.getElementById("user_captcha_input").value = "";
    }

    return validateCaptcha(user_captcha);
  };

  doSubmit = async () => {};

  render() {
    return (
      <div className="d-flex align-items-stretch">
        <div className="row" style={{ flex: "1" }}>
          <div
            className="col-12 col-lg-6 d-none d-lg-block"
            style={{
              backgroundColor: "#46196D",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
          <div
            className="col-12 col-lg-6 desktopResponsive"
            style={{
              paddingTop: "24px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              className="d-none d-lg-block mx-auto"
              style={{
                marginTop: "62px",
                marginBottom: "56px",
                height: "64px",
              }}
            />
            <h1
              className="mx-auto d-lg-none"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                width: "296px",
                paddingBottom: "24px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Distributed Energy Management System
            </h1>
            <h1
              className="d-none d-lg-block"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                paddingBottom: "108px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Recover password
            </h1>
            <form
              style={{
                fontFamily: "iranyekanwebregular",
              }}
              onSubmit={this.handelSubmit}
            >
              {this.renderInput(
                "code",
                "Please enter the code which has been sent to phone number +46 760965655.",
                "Enter code"
              )}
              <p
                className="text-purple-950"
                style={{
                  marginTop: "24px",
                  paddingBottom: "32px",
                  fontWeight: "500",
                }}
              >
                Change phone number
              </p>
              <div style={{ marginTop: "24px", marginBottom: "32px" }}>
                {!this.state.buttonStatus && (
                  <Captcha error={this.state.errorCaptcha} />
                )}
              </div>
              <ResendButton
                buttonStatus={this.buttonStatus}
                handelResendCode={this.handelResendCode}
                checkCaptcha={this.checkCaptcha}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword2;
