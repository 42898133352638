import React, { Component } from "react";
import Input from "./input";
import Joi from "joi-browser";
import InputSelect from "./inputSelect";

class Form extends Component {
  state = { data: {}, errors: {} };

  validate = () => {
    const option = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, option);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handelChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handelSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  renderButton(label) {
    return (
      <button
        style={{ fontFamily: "iranyekanwebmedium" }}
        disabled={this.validate()}
        type="submit"
        className="bg-purple-950 hover:bg-purple-900 text-Slate-0 py-2 px-4 border border-purple-950 rounded w-100 disabled:bg-[#F0F3F5] disabled:text-[#B2BEC3]"
      >
        {label}
      </button>
    );
  }

  renderButtonModel(label, modal) {
    return (
      <button
        data-bs-toggle="modal"
        data-bs-target={modal}
        style={{ fontFamily: "iranyekanwebmedium" }}
        disabled={this.validate()}
        type="submit"
        className="bg-purple-950 hover:bg-purple-900 text-Slate-0 py-2 px-4 border border-purple-950 rounded w-100 disabled:bg-[#F0F3F5] disabled:text-[#B2BEC3]"
      >
        {label}
      </button>
    );
  }

  renderButtonCancel() {
    return (
      <button
        onClick={() => this.handelCancel()}
        style={{ fontFamily: "iranyekanwebmedium", borderWidth: "1px" }}
        className="bg-Slate-0 hover:bg-purple-900 hover:text-white text-purple-950 border-purple-950 py-2 px-4 rounded w-100"
      >
        cancel
      </button>
    );
  }

  renderInput(name, label, placeholder, suffix = "", type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        placeholder={placeholder}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        suffix={suffix}
        onChange={this.handelChange}
        error={errors[name]}
      />
    );
  }

  renderSelect(name, label, options, placeholder) {
    const { data, errors } = this.state;
    return (
      <InputSelect
        placeholder={placeholder}
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handelChange}
        error={errors[name]}
      />
    );
  }
}

export default Form;
