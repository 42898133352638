import React, { useState, useEffect } from "react";
import icons from "./common/icons";
import InformationShow from "./common/informationShow";
import InputInformation from "./common/inputInformation";
import InputSelectInformation from "./common/inputSelectInformation";

function ProposalInfo(props) {
  const [infoActive, setInfoActive] = useState(true);
  const [powerActive, setPowerActive] = useState(false);
  const [locationActive, setLocationActive] = useState(false);

  const [options, setOptions] = useState([
    { _id: 0, name: "rental" },
    { _id: 1, name: "residence" },
  ]);

  const styles = {
    colorInfo: {
      borderBottom: infoActive ? "2px solid #46196D" : "1px solid #2D3436",
    },
    buttonColorInfo: {
      color: infoActive ? "#46196D" : "#2D3436",
    },

    colorPower: {
      borderBottom: powerActive ? "2px solid #46196D" : "1px solid #2D3436",
    },
    buttonColorPower: {
      color: powerActive ? "#46196D" : "#2D3436",
    },

    colorLocation: {
      borderBottom: locationActive ? "2px solid #46196D" : "1px solid #2D3436",
    },
    buttonColorLocation: {
      color: locationActive ? "#46196D" : "#2D3436",
    },
  };

  const setValue = (info, power, location) => {
    setInfoActive(info);
    setPowerActive(power);
    setLocationActive(location);
  };

  return (
    <div>
      <div
        className="flex justify-center gap-1"
        style={{ paddingTop: "32px", paddingBottom: "50px" }}
      >
        <div
          className="row align-items-center"
          onClick={() => {
            setValue(true, false, false);
          }}
          style={styles.colorInfo}
        >
          <span className="col-1">{icons.infoIcon(infoActive)}</span>
          <button className="col" style={styles.buttonColorInfo}>
            info
          </button>
        </div>

        <div
          className="row align-items-center"
          style={styles.colorPower}
          onClick={() => {
            setValue(false, true, false);
          }}
        >
          <span className="col-1">{icons.powerIcon(powerActive)}</span>
          <button className="col" style={styles.buttonColorPower}>
            Power branch information
          </button>
        </div>

        <div
          className="row align-items-center"
          style={styles.colorLocation}
          onClick={() => {
            setValue(false, false, true);
          }}
        >
          <span className="col-1">{icons.locationIcon(locationActive)}</span>
          <button className="col" style={styles.buttonColorLocation}>
            Location Information
          </button>
        </div>
      </div>

      {infoActive && (
        <div style={{ marginLeft: "32px" }}>
          <div className="grid grid-cols-2" style={{ marginBottom: "32px" }}>
            <div style={{ paddingRight: "56px", marginBottom: "24px" }}>
            <InformationShow
                label="Mobile phone number"
                value={props.proposal.phoneNumber}
              />
            </div>
            <div style={{ paddingRight: "56px", marginBottom: "24px" }}>
              <InformationShow
                label="ID Number"
                value={props.proposal.nationalId}
              />
            </div>
            <div className="col-span-2" style={{ paddingRight: "56px" }}>
              <InformationShow
                label="Address where you live"
                value="tehran saadat abadi"
              />
            </div>
          </div>
        </div>
      )}

      {powerActive && (
        <div style={{ marginLeft: "32px" }}>
          <div className="grid grid-cols-3" style={{ marginBottom: "32px" }}>
            <div style={{ paddingRight: "56px" }}>
              <InformationShow label="Type of power meter" value="Smart" />
            </div>
            <div style={{ paddingRight: "56px" }}>
              <InformationShow
                label="Max Power Consumption (KWH) - Last Period"
                value="230"
              />
            </div>
            <div style={{ paddingRight: "56px", marginBottom: "24px" }}>
              <InformationShow
                label="Min Power Consumption (KWH) - Last Period"
                value="230"
              />
            </div>
            <div style={{ paddingRight: "56px" }}>
            <InformationShow
                label="Meter Number"
                value={props.proposal.meter_number}
              />
            </div>
          </div>
        </div>
      )}

      {locationActive && (
        <div style={{ marginLeft: "32px" }}>
          <div className="grid grid-cols-2" style={{ marginBottom: "32px" }}>
            <div style={{ paddingRight: "56px", marginBottom: "24px" }}>
              <InputInformation
                name="meterage"
                label="Meterage"
                value={props.data.meterage}
                handelChange={props.handelChange}
              />
            </div>
            <div style={{ paddingRight: "56px", marginBottom: "24px" }}>
            <InformationShow
                label="Location Zip Code"
                value={props.proposal.postalCode}
              />
            </div>
            <div style={{ paddingRight: "56px" }}>
              <InputSelectInformation
                name="ownership"
                label="Type of residency"
                value={props.data.ownership}
                handelChange={props.handelChange}
                options={options}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProposalInfo;
