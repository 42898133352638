import React from "react";
import "./input.css";

const InputSelect = ({ name, label, options, error, placeholder, ...rest }) => {
  return (
    <div className="mb-3" style={{ paddingBottom: "8px" }}>
      <label
        htmlFor={name}
        style={{ paddingBottom: "8px" }}
        className="form-label"
      >
        {label}
      </label>
      <select className="form-select" {...rest} name={name} id={name}>
        <option disabled value="">
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option._id} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
      {error && (
        <div
          className="bg-purple-300 border-l-4 border-purple-950 text-purple-950 p-2"
          role="alert"
          style={{ marginTop: "10px", fontSize: "13px" }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default InputSelect;
