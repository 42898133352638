import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import ProfileHeader from "./common/profileHeader";
import auth from "../services/authService";
import "../App.css";

function UserProfile(props) {
  const [balance, setBalance] = useState("");

  useEffect(() => {
    async function getUserInformation() {
      const result = await auth.getUserBalance();
      setBalance(result.value);
    }

    getUserInformation();
  });

  if (!auth.getCurrentUser()) return <Navigate to="/home" />;

  return (
    <div>
      <ProfileHeader />
      <div className="profileCard" style={{ textAlign: "center" }}>
        <div style={{ paddingTop: "24px" }}>
          <div className="accountBalance">
            <p
              style={{ fontFamily: "iranyekanwebregular" }}
              className="accountBalanceTitle"
            >
              Your current balance:
            </p>
            <p
              style={{ fontFamily: "iranyekanwebregular" }}
              className="accountBalanceContent"
            >
              {balance} EC
            </p>
            <Link to="/submit_request">
              <button
                style={{ fontFamily: "iranyekanwebbold" }}
                className="accountBalanceButton"
              >
                + Submit Request
              </button>
            </Link>
          </div>
          {balance !== "0" && (
            <div
              style={{
                marginTop: "24px",
              }}
            >
              <button
                className="bg-purple-950 hover:bg-purple-900 text-white py-2 px-4 border border-purple-950 rounded"
                style={{ width: "90%" }}
                onClick={() => (window.location = "/transfer-token")}
              >
                Transfer Token
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
