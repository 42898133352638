import React from "react";

function InputSelectInformation({
  name,
  label,
  value,
  handelChange,
  placeholder = "",
  options,
}) {
  return (
    <div>
      <label
        style={{
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "16px",
          alignItems: "center",
          marginBottom: "16px",
          color: "#7B7B7B ",
        }}
      >
        {label}
      </label>
      <select
        style={{ height: "48px" }}
        className="form-select"
        onChange={handelChange}
        name={name}
        id={name}
        value={value}
      >
        <option disabled value="">
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option._id} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default InputSelectInformation;
