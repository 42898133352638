import React from "react";
import { Navigate } from "react-router-dom";
import Form from "./common/form";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import auth from "../services/authService";
import ProfileHeader from "./common/profileHeader";
import Success from "./common/success";
import "../App.css";

class SubmitRequest extends Form {
  state = {
    data: { postalCode: "", ownership: "", meterNumber: "" },
    isAdmin: "",
    errors: {},
    options: [
      { _id: 0, name: "Rental" },
      { _id: 1, name: "Residence" },
    ],
    submit: false,
    success: false,
    successMessage:
      "You request has been registered successfuly, Please wait for Adminstrtor's approval.",
  };

  schema = {
    postalCode: Joi.string().required().label("Postal Code"),
    ownership: Joi.string().required().label("Ownership Type"),
    meterNumber: Joi.string().required().label("meter Number"),
  };

  async componentDidMount() {
    const result = auth.getCurrentUser();
    this.setState({ isAdmin: result["is_super"] });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.submit !== this.state.submit) {
      if (this.state.submit) {
        this.doSubmit();
      }
    }
  }

  doSubmit = async () => {
    if (this.state.submit) {
      try {
        const { data } = this.state;
        const body = {
          postalCode: data.postalCode,
          ownership: data.ownership,
          meter_number: data.meterNumber,
        };
        const result = await auth.SubmitRequest(body);

        this.setState({ success: true });
      } catch (ex) {
        if (ex["response"]["status"] === 400) {
          toast.error("Error");
          const timer = setTimeout(() => {
            window.location.href = "/profile";
          }, 5000);
        } else {
          console.log(ex);
        }
      }
    }
  };

  doSubmitModel = () => {
    this.setState({ submit: true });
  };

  handelCancel = () => {
    window.location = "/profile";
  };

  render() {
    if (!auth.getCurrentUser()) return <Navigate to="/home" />;

    if (this.state.isAdmin) return <Navigate to="/profile" />;

    if (this.state.success) {
      return <Success message={this.state.successMessage} path="/profile" />;
    }

    return (
      <div>
        <ProfileHeader />
        <div className="SubmitRequestCard">
          <div style={{ paddingTop: "32px" }}>
            <form
              style={{
                fontFamily: "iranyekanwebregular",
              }}
              onSubmit={this.handelSubmit}
            >
              {this.renderInput("postalCode", "Residence postal code", "")}

              {this.renderSelect(
                "ownership",
                "Ownership type",
                this.state.options,
                "Choose ownership type"
              )}
              {this.renderInput("meterNumber", "Electricity meter number", "")}
              <div className="grid grid-cols-2" style={{ gap: "14px" }}>
                <div className="col">{this.renderButtonCancel()}</div>
                <div className="col">
                  {this.renderButtonModel("confirm", "#confirmModel")}
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="confirmModel"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Modal title
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div> */}
              <div className="modal-body" style={{ marginBottom: "24px" }}>
                Do you verify your info?
              </div>
              <div className="modal-footer">
                <div className="grid grid-cols-2" style={{ gap: "14px" }}>
                  <button
                    type="button"
                    style={{
                      fontFamily: "iranyekanwebmedium",
                      borderWidth: "1px",
                    }}
                    className="bg-[#F0F3F5] hover:bg-purple-900 hover:text-white text-[#B2BEC3] border-[#B2BEC3] py-2 px-4 rounded w-100"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    style={{ fontFamily: "iranyekanwebmedium" }}
                    className="bg-purple-950 hover:bg-purple-900 text-Slate-0 py-2 px-4 border border-purple-950 rounded w-100"
                    data-bs-dismiss="modal"
                    onClick={this.doSubmitModel}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}
      </div>
    );
  }
}

export default SubmitRequest;
