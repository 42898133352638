import React, { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import auth from "../services/authService";
import logo from "../images/Logo.png";
import icons from "./common/icons";
import headerImage from "../images/header.png";
import ProposalInfo from "./proposalInfo";

function ProposalConfirm(props) {
  const { id } = useParams();
  const [proposal, setProposal] = useState([]);
  const [data, setDate] = useState({
    id: parseInt(id),
    phoneNumber: "",
    postalCode: "",
    ownership: "",
    meter_number: "",
    meterage: "",
  });
  const [flag, setFlag] = useState(0);

  useEffect(() => {
    async function getUserInformation() {
      if (flag === 0) {
        const result = await auth.getAllProposals();
        const user = result["data"].find((p) => p.ID === parseInt(id));
        setProposal(user);
        const newData = data;
        data.phoneNumber = user.phoneNumber;
        data.postalCode = user.postalCode;
        data.ownership = user.ownership;
        data.meter_number = user.meter_number;
        data.meterage = user.meterage;
        setDate(newData);
      }
    }

    getUserInformation();
  }, [flag]);

  const handelChange = ({ currentTarget: input }) => {
    const newData = data;
    newData[input.name] = input.value;
    setDate(newData);
    setFlag(flag + 1);
  };

  const handelConfirm = async () => {
    try {
      const result = await auth.acceptPorposal(data);
    } catch (ex) {
      console.log(ex);
    }
  };

  const handelReject = async () => {
    const body = { id: data.id };
    try {
      const result = await auth.rejectPorposal(body);
      window.location = "/profile";
    } catch (ex) {
      console.log(ex);
    }
  };

  if (!auth.getCurrentUser()) return <Navigate to="/home" />;

  if (!auth.getCurrentUser().is_super) return <Navigate to="/profile" />;

  return (
    <div className="d-flex align-items-stretch">
      <div className="row" style={{ flex: "1" }}>
        <div
          className="col-3"
          style={{ backgroundColor: "white", minHeight: "100vh" }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              marginLeft: "24px",
              marginTop: "24px",
              marginBottom: "36px",
              height: "64px",
            }}
          />
          <p
            style={{
              fontWeight: "700",
              fontSize: "12px",
              lineHeight: "24px",
              letterSpacing: "0.03em",
              color: "#636E72",
              marginLeft: "24px",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            MAIN
          </p>
          <div style={{ marginLeft: "24px" }}>
            <div className="row align-items-center">
              <div className="col-2">{icons.dashboardIcon()}</div>
              <div className="col-7">
                <p
                  className="my-auto"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.02em",
                    color: "#636E72",
                  }}
                >
                  Dashboard
                </p>
              </div>
              <div className="col-2">{icons.downArrowIcon()}</div>
            </div>
          </div>

          <div
            className="bg-[#ede9fe] border-r-4 border-[#46196D] p-2"
            style={{ marginLeft: "24px", marginTop: "16px" }}
          >
            <div className="row align-items-center">
              <div className="col-2">{icons.requestsIcon()}</div>
              <div className="col">
                <p
                  className="my-auto"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.02em",
                    color: "#46196D",
                  }}
                >
                  Requests
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-9">
          <div
            style={{
              height: "64px",
              width: "calc(100%-64px)",
              marginLeft: "32px",
              marginRight: "32px",
              marginTop: "16px",
              marginBottom: "16px",
              top: "16px",
              backgroundColor: "white",
              borderRadius: "7px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={headerImage}
              alt="header image"
              style={{ marginRight: "24px" }}
              className="my-auto"
            />
          </div>
          <div
            style={{
              backgroundColor: "white",
              width: "calc(100%-64px)",
              marginLeft: "32px",
              marginRight: "32px",
              height: "calc(100% - 136px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ProposalInfo
              proposal={proposal}
              data={data}
              handelChange={handelChange}
            />
            <div
              className="grid grid-cols-4"
              style={{
                textAlign: "center",
                gap: "14px",
                marginLeft: "32px",
                marginRight: "32px",
                fontFamily: "iranyekanwebmedium",
                marginTop: "auto",
                marginBottom: "32px",
              }}
            >
              <button
                style={{ borderWidth: "1px" }}
                className="bg-Slate-0 hover:bg-purple-900 hover:text-white text-purple-950 border-purple-950 py-2 px-4 rounded w-100 col-start-3"
                onClick={() => handelReject()}
              >
                reject
              </button>
              <button
                data-bs-toggle="modal"
                data-bs-target="#comfirmedModel"
                style={{ marginRight: "32px" }}
                className="bg-purple-950 hover:bg-purple-900 text-white py-2 px-4 border border-purple-950 rounded w-100 col-start-4"
                onClick={() => handelConfirm()}
              >
                confirm
              </button>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="comfirmedModel"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              {/* <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> */}
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-2">
                    {icons.checkIcon("#00F7BF", "24")}
                  </div>
                  <div className="col" style={{ verticalAlign: "middle" }}>
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.02em",
                        margin: "0 auto",
                        color: "#111314",
                      }}
                    >
                      confirmed
                    </p>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-2"></div>
                  <div className="col" style={{ verticalAlign: "middle" }}>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        letterSpacing: "0.02em",
                        margin: "0 auto",
                        color: "#636E72",
                      }}
                    >
                      {proposal.nationalId}
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="bg-purple-950 hover:bg-purple-900 text-white py-2 px-4 border border-purple-950 rounded"
                  data-bs-dismiss="modal"
                  onClick={() => (window.location = "/profile")}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProposalConfirm;
