import React from "react";
import { Link } from "react-router-dom";
import homePageImage from "../images/homePageImage.png";
import logo from "../images/Logo.png";
import "../App.css";

function Home(props) {
  return (
    <div className="d-flex align-items-stretch">
      <div className="row" style={{ flex: "1" }}>
        <div
          className="col-12 col-lg-6 d-none d-lg-block"
          style={{
            backgroundColor: "#46196D",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        ></div>
        <div
          className="col-12 col-lg-6 desktopResponsive"
          style={{
            paddingTop: "24px",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="d-none d-lg-block mx-auto"
            style={{ marginTop: "62px", marginBottom: "56px", height: "64px" }}
          />
          <h1
            className="mx-auto"
            style={{
              fontSize: "32px",
              fontWeight: "700",
              lineHeight: "42px",
              paddingBottom: "16px",
              fontFamily: "iranyekanwebbold",
            }}
          >
            Distributed Energy Management System
          </h1>
          <img
            className="mx-auto d-lg-none"
            style={{
              paddingBottom: "32px",
              paddingLeft: "32px",
              paddingRight: "32px",
            }}
            src={logo}
            alt="homePageImage"
          />
          <div
            className="grid grid-cols-2 lg:grid-cols-1"
            style={{
              textAlign: "center",
              gap: "14px",
              marginLeft: "4px",
              marginRight: "4px",
              fontFamily: "iranyekanwebmedium",
            }}
          >
            <Link to="/login" className="order-0 order-lg-1">
              <button
                style={{ borderWidth: "1px" }}
                className="bg-Slate-0 hover:bg-purple-900 hover:text-white text-purple-950 border-purple-950 py-2 px-4 rounded w-100"
              >
                login
              </button>
            </Link>
            <Link to="/register" className="order-1 order-lg-0">
              <button className="bg-purple-950 hover:bg-purple-900 text-white py-2 px-4 border border-purple-950 rounded w-100">
                register
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
