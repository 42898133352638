import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import auth from "../services/authService";
import Form from "./common/form";
import ProfileHeader from "./common/profileHeader";
import Joi from "joi-browser";
import Success from "./common/success";
import "../App.css";

class TransferToken extends Form {
  state = {
    data: { walletNumber: "", exchangeAmount: "" },
    isAdmin: "",
    balance:"",
    errors: {},
    submit: false,
    successMessage: "Your transaction has been successful.",
    success: false,
  };

  schema = {
    walletNumber: Joi.string().required().label("Wallet Number"),
    exchangeAmount: Joi.string().required().label("Exchange Amount"),
  };

  async componentDidMount() {
    const result1 = auth.getCurrentUser();
    this.setState({ isAdmin: result1["is_super"] });

    const result2 = await auth.getUserBalance();
    this.setState({ balance: result2["value"] });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.submit !== this.state.submit) {
      if (this.state.submit) {
        this.doSubmit();
      }
    }
  }

  doSubmit = async () => {
    if (this.state.submit) {
      try {
        const { data } = this.state;
        const body = {
          receiver: data.walletNumber,
          value: data.exchangeAmount,
        };
        const result = await auth.transferToken(body);

        this.setState({ success: true });

      } catch (ex) {
        toast.error("Failed to make the transaction");
        console.log(ex)
      }
    }
  };

  doSubmitModel = () => {
    this.setState({ submit: true });
  };

  handelCancel = () => {
    window.location = "/profile";
  };

  render() {
    if (!auth.getCurrentUser()) return <Navigate to="/home" />;

    if (this.state.isAdmin) return <Navigate to="/profile" />;

    if (this.state.balance === "0") return <Navigate to="/profile" />;

    if (this.state.success) {
      return <Success message={this.state.successMessage} path="/profile" />;
    }

    return (
      <div>
        <ProfileHeader />
        <div
          className="transferToken"
          style={{ fontFamily: "iranyekanwebregular" }}
        >
          <div style={{ paddingTop: "32px" }}>
            <div style={{ textAlign: "center" }}>
              <h1>Your current balance:</h1>
              <h1 style={{ marginTop: "32px", marginBottom: "24px" }}>
                {this.state.balance} EC
              </h1>
            </div>
            <form onSubmit={this.handelSubmit}>
              {this.renderInput(
                "walletNumber",
                "Receiving wallet account number",
                ""
              )}

              {this.renderInput("exchangeAmount", "Exchange amount", "","EC")}

              <div className="grid grid-cols-2" style={{ gap: "14px" }}>
                <div className="col">{this.renderButtonCancel()}</div>
                <div className="col">
                  {this.renderButtonModel("confirm", "#confirmModel")}
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="confirmModel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Modal title
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div> */}
              <div className="modal-body" style={{ marginBottom: "24px" }}>
                Do you verify this transaction?
              </div>
              <div className="modal-footer">
                <div className="grid grid-cols-2" style={{ gap: "14px" }}>
                  <button
                    type="button"
                    style={{
                      fontFamily: "iranyekanwebmedium",
                      borderWidth: "1px",
                    }}
                    className="bg-[#F0F3F5] hover:bg-purple-900 hover:text-white text-[#B2BEC3] border-[#B2BEC3] py-2 px-4 rounded w-100"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    style={{ fontFamily: "iranyekanwebmedium" }}
                    className="bg-purple-950 hover:bg-purple-900 text-Slate-0 py-2 px-4 border border-purple-950 rounded w-100"
                    onClick={ this.doSubmitModel}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}
      </div>
    );
  }
}

export default TransferToken;
