import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

function Loading(props) {
  const override = {
    margin: "auto",
  };

  return (
    <div
      className="bg-purple-950"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ flex: "1" }}>
        {" "}
        <div>
          <p
            style={{
              fontFamily: "iranyekanwebbold",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "16px",
              color: "#FFFFFF",
              marginBottom: "46px",
            }}
          >
            {props.message}
          </p>
        </div>
        <div className="mx-auto">
          <div>
            <FadeLoader color="#D9D9D9" cssOverride={override} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
