import React, { useState, useEffect } from "react";
import Register1 from "./register1";
import Register2 from "./register2";

function Register(props) {
  const [register, setRegister] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nationalId, setNationalId] = useState("");

  function changeRegisterPage(pageNumber, phoneNumber, nationalId) {
    setPhoneNumber(phoneNumber);
    setNationalId(nationalId);
    setRegister(pageNumber);
  }

  return (
    <div>
      {register === 1 && <Register1 changeRegisterPage={changeRegisterPage} />}
      {register === 2 && (
        <Register2 phoneNumber={phoneNumber} nationalId={nationalId} />
      )}
    </div>
  );
}

export default Register;
