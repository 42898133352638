import { useEffect } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

function Captcha(props) {
  return (
    <div>
      <div></div>
      <div className="my-2">
        <label htmlFor="user_captcha_input" style={{ marginBottom: "16px" }}>
          Enter the given code.
        </label>
        <div className="row">
          <div className="col">
            <LoadCanvasTemplateNoReload />
          </div>
          <div className="col">
            <input
              className="form-control"
              id="user_captcha_input"
              name="user_captcha_input"
              type="text"
            />
            {props.error && (
              <div
                className="bg-purple-300 border-l-4 border-purple-950 text-purple-950 p-2"
                role="alert"
                style={{ marginTop: "10px", fontSize: "13px" }}
              >
                {props.error}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Captcha;
