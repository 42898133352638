import React from "react";
import "./input.css";

function InputInformation({
  name,
  label,
  value,
  handelChange,
  placeholder = "",
}) {
  return (
    <div>
      <label
        style={{
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "16px",
          alignItems: "center",
          marginBottom: "16px",
          color: "#7B7B7B ",
        }}
      >
        {label}
      </label>
      <input
        style={{ height: "48px" }}
        className="form-control"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handelChange}
      />
    </div>
  );
}

export default InputInformation;
