import React, { useState } from "react";
import Login1 from "./login1";
import Login2 from "./login2";

function Login(props) {
  const [login, setLogin] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  function changeLoginPage(pageNumber, phoneNumber = "", password = "") {
    setPhoneNumber(phoneNumber);
    setPassword(password);
    setLogin(pageNumber);
  }

  return (
    <div>
      {login === 1 && <Login1 changeLoginPage={changeLoginPage} />}
      {login === 2 && <Login2 phoneNumber={phoneNumber} password={password} />}
    </div>
  );
}

export default Login;
