import React from "react";
import icons from "./icons";

function Success(props) {
  return (
    <div className="bg-purple-950">
      <div
        style={{ position: "absolute", right: "16px", top: "16px" }}
        onClick={() => (window.location = props.path)}
      >
        {icons.crossIcon()}
      </div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div style={{ flex: "1" }}>
          <div>
            <div className="mx-auto">
              <div>{icons.checkIcon()}</div>
            </div>
            <p
              style={{
                fontFamily: "iranyekanwebbold",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "16px",
                color: "#FFFFFF",
                marginTop: "32px",
              }}
            >
              {props.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
