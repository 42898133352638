import React from "react";
import { Navigate } from "react-router-dom";
import auth from "../services/authService";
import logo from "../images/Logo.png";
import icons from "./common/icons";
import headerImage from "../images/header.png";
import profileTableHeader from "../images/profileTableHeader.png";
import AdminProfileTable from "./adminProfileTable";

function AdminProfile(props) {
  if (!auth.getCurrentUser()) return <Navigate to="/home" />;
  return (
    <div className="d-flex align-items-stretch">
      <div className="row" style={{ flex: "1" }}>
        <div
          className="col-3"
          style={{ backgroundColor: "white", minHeight: "100vh" }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              marginLeft: "24px",
              marginTop: "24px",
              marginBottom: "36px",
              height: "64px",
            }}
          />
          <p
            style={{
              fontWeight: "700",
              fontSize: "12px",
              lineHeight: "24px",
              letterSpacing: "0.03em",
              color: "#636E72",
              marginLeft: "24px",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            MAIN
          </p>
          <div style={{ marginLeft: "24px" }}>
            <div className="row align-items-center">
              <div className="col-2">{icons.dashboardIcon()}</div>
              <div className="col-7">
                <p
                  className="my-auto"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.02em",
                    color: "#636E72",
                  }}
                >
                  Dashboard
                </p>
              </div>
              <div className="col-2">{icons.downArrowIcon()}</div>
            </div>
          </div>

          <div
            className="bg-[#ede9fe] border-r-4 border-[#46196D] p-2"
            style={{ marginLeft: "24px", marginTop: "16px" }}
          >
            <div className="row align-items-center">
              <div className="col-2">{icons.requestsIcon()}</div>
              <div className="col">
                <p
                  className="my-auto"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.02em",
                    color: "#46196D",
                  }}
                >
                  Requests
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-9">
          <div
            style={{
              height: "64px",
              width: "calc(100%-64px)",
              marginLeft: "32px",
              marginRight: "32px",
              marginTop: "16px",
              marginBottom: "16px",
              top: "16px",
              backgroundColor: "white",
              borderRadius: "7px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={headerImage}
              alt="header image"
              style={{ marginRight: "24px" }}
              className="my-auto"
            />
          </div>
          <div
            style={{
              backgroundColor: "white",
              width: "calc(100%-64px)",
              // height: "90vh",
              marginLeft: "32px",
              marginRight: "32px",
            }}
          >
            <div className="grid grid-cols-2">
              <div>
                <img
                  src={profileTableHeader}
                  alt="profileTableHeader"
                  style={{
                    marginTop: "20px",
                    marginLeft: "24px",
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "16px",
                  marginRight: "24px",
                }}
                className="col-end-4"
              >
                {icons.searchIcon()}
              </div>
            </div>
            <AdminProfileTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminProfile;
