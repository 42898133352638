import React, { useState, useEffect } from "react";

const ResendButton = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(3 * 60);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDisabled(false);
    }, timeLeft * 1000);

    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
      props.buttonStatus(document.getElementById("subButton").disabled);
    };
  }, [isDisabled]);

  const minutes = Math.floor(timeLeft / 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const seconds = (timeLeft % 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const submit = () => {
    const check = props.checkCaptcha();
    if (check) {
      setIsDisabled(true);
      setTimeLeft(3 * 60);
      props.handelResendCode();
    }
  };

  return (
    <button
      id="subButton"
      className="disabled:bg-[#F0F3F5] disabled:text-[#B2BEC3] text-Slate-0 bg-purple-950 hover:bg-purple-900 py-2 px-4 border border-purple-950 rounded w-100"
      disabled={isDisabled}
      onClick={() => submit()}
    >
      {isDisabled ? `Resend Code (${minutes} : ${seconds})` : "Resend Code"}
    </button>
  );
};

export default ResendButton;
