import React from "react";
import "./input.css";

const Input = ({ name, label, error, placeholder, suffix, ...rest }) => {
  return (
    <div className="mb-3" style={{ paddingBottom: "8px" }}>
      <label
        htmlFor={name}
        className="form-label"
        style={{ paddingBottom: "8px" }}
      >
        {label}
      </label>
      <div className="input-group">
        <input
          className="form-control"
          placeholder={placeholder}
          {...rest}
          name={name}
          id={name}
        />
        {suffix && (
          <span class="input-group-text" id="basic-addon2">
            {suffix}
          </span>
        )}
      </div>

      {error && (
        <div
          className="bg-purple-300 border-l-4 border-purple-950 text-purple-950 p-2"
          role="alert"
          style={{ marginTop: "10px", fontSize: "13px" }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default Input;
