import React from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import auth from "../services/authService";
import Form from "./common/form";
import Captcha from "./common/captcha";
import Loading from "./common/loading";
import Success from "./common/success";
import logo from "../images/Logo.png";

class Register2 extends Form {
  state = {
    data: { password: "", repeat_password: "" },
    errors: {},
    errorCaptcha: "",
    loadingMessage: "Generating Wallet...",
    successMessage: "Your registration has been successful.",
    loading: false,
    success: false,
  };

  schema = {
    password: Joi.string().min(3).max(15).required().label("Password"),
    repeat_password: Joi.any()
      .valid(Joi.ref("password"))
      .required()
      .options({ language: { any: { allowOnly: "must match password" } } })
      .label("Repeat Password"),
  };

  componentDidMount() {
    loadCaptchaEnginge(6, "white", "gray");
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.data.repeat_password !== this.state.data.repeat_password) {
      if (!this.validate()) {
        this.setState({ errors: {} });
      }
    }
  }

  doSubmit = async () => {
    let user_captcha = document.getElementById("user_captcha_input").value;

    //check captcha
    if (validateCaptcha(user_captcha) === true) {
      this.setState({ errorCaptcha: "" });
      document.getElementById("user_captcha_input").value = "";
    } else {
      this.setState({ errorCaptcha: "Captcha Does Not Match" });
      document.getElementById("user_captcha_input").value = "";
    }

    if (validateCaptcha(user_captcha) === true) {
      this.setState({ loading: true });
      try {
        const { data } = this.state;
        const body = {
          phoneNumber: this.props.phoneNumber,
          nationalId: this.props.nationalId,
          password: data.password,
        };
        const result = await auth.register(body);

        this.setState({ loading: false });
        this.setState({ success: true });
      } catch (ex) {
        if (ex["response"]["status"] === 400) {
          toast.error("This user already has account");
          const timer = setTimeout(() => {
            window.location.href = "/register";
          }, 5000);
        }
      }
    }
  };

  render() {
    if (this.state.loading) {
      return <Loading message={this.state.loadingMessage} />;
    }

    if (this.state.success) {
      return <Success message={this.state.successMessage} path="/login" />;
    }

    return (
      <div className="d-flex align-items-stretch">
        <div className="row" style={{ flex: "1" }}>
          <div
            className="col-12 col-lg-6 d-none d-lg-block"
            style={{
              backgroundColor: "#46196D",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
          <div
            className="col-12 col-lg-6 desktopResponsive"
            style={{
              paddingTop: "24px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              className="d-none d-lg-block mx-auto"
              style={{ marginTop: "62px", marginBottom: "56px" }}
            />
            <h1
              className="mx-auto d-lg-none"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                width: "296px",
                paddingBottom: "24px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Distributed Energy Management System
            </h1>
            <h1
              className="d-none d-lg-block"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                paddingBottom: "108px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Rgister
            </h1>
            <form
              style={{
                fontFamily: "iranyekanwebregular",
              }}
              onSubmit={this.handelSubmit}
            >
              {this.renderInput(
                "password",
                "password",
                "Password must be at least 8 characters.",
                "",
                "password"
              )}
              {this.renderInput(
                "repeat_password",
                "Repeat password",
                "Enter password again.",
                "",
                "password"
              )}
              <div style={{ marginTop: "24px", marginBottom: "32px" }}>
                <Captcha error={this.state.errorCaptcha} />
              </div>
              <div>{this.renderButton("next")}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Register2;
