import React from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import auth from "../services/authService";
import Form from "./common/form";
import Captcha from "./common/captcha";
import Loading from "./common/loading";
import logo from "../images/Logo.png";

class Register1 extends Form {
  state = {
    data: { phoneNumber: "", nationalId: "" },
    errors: {},
    errorCaptcha: "",
    loadingMessage: "Receiving permission...",
    loading: false,
  };

  schema = {
    phoneNumber: Joi.string()
      .required()
      .min(10)
      .max(14)
      .regex(/^[0-9]/)
      .label("Phone Number"),
    nationalId: Joi.string()
      .required()
      .length(10)
      .regex(/^[0-9]/)
      .label("National ID"),
  };

  componentDidMount() {
    loadCaptchaEnginge(6, "white", "gray");
  }

  async componentDidUpdate(prevProps, prevState) {
    // if (prevState.loading !== this.state.loading) {
    //   loadCaptchaEnginge(6, "white", "gray");
    // }
  }

  doSubmit = async () => {
    let user_captcha = document.getElementById("user_captcha_input").value;

    //check captcha
    if (validateCaptcha(user_captcha) === true) {
      this.setState({ errorCaptcha: "" });
      document.getElementById("user_captcha_input").value = "";
    } else {
      this.setState({ errorCaptcha: "Captcha Does Not Match" });
      document.getElementById("user_captcha_input").value = "";
    }

    if (validateCaptcha(user_captcha) === true) {
      this.setState({ loading: true });
      try {
        const { data } = this.state;
        const body = {
          phoneNumber: data.phoneNumber,
          nationalId: data.nationalId,
        };
        await auth.registercheck(body);

        this.props.changeRegisterPage(2, data.phoneNumber, data.nationalId);
      } catch (ex) {
        if (ex["response"]["status"] === 400) {
          this.setState({ loading: false });
          toast.error("NationalID or PhoneNumber is not correct");
        }
      }
    }
  };

  render() {
    if (this.state.loading) {
      return <Loading message={this.state.loadingMessage} />;
    }

    return (
      <div className="d-flex align-items-stretch">
        <div className="row" style={{ flex: "1" }}>
          <div
            className="col-12 col-lg-6 d-none d-lg-block"
            style={{
              backgroundColor: "#46196D",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
          <div
            className="col-12 col-lg-6 desktopResponsive"
            style={{
              paddingTop: "24px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              className="d-none d-lg-block mx-auto"
              style={{
                marginTop: "62px",
                marginBottom: "56px",
                height: "64px",
              }}
            />
            <h1
              className="mx-auto d-lg-none"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                width: "296px",
                paddingBottom: "24px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Distributed Energy Management System
            </h1>
            <h1
              className="d-none d-lg-block"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                paddingBottom: "108px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Rgister
            </h1>
            <form
              style={{
                fontFamily: "iranyekanwebregular",
              }}
              onSubmit={this.handelSubmit}
            >
              {this.renderInput(
                "phoneNumber",
                "Mobile phone number",
                "Enter the phone number you own."
              )}
              {this.renderInput(
                "nationalId",
                "ID number",
                "Enter you national ID"
              )}
              <div style={{ marginTop: "24px", marginBottom: "32px" }}>
                <Captcha error={this.state.errorCaptcha} />
              </div>
              {this.props.validatePhoneMessage && (
                <div
                  className="bg-purple-300 border-l-4 border-purple-950 text-purple-950 p-2"
                  role="alert"
                  style={{ marginTop: "10px", fontSize: "13px" }}
                >
                  {this.props.validatePhoneMessage}
                </div>
              )}
              <div>{this.renderButton("next")}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Register1;
