import React from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import Joi from "joi-browser";
import auth from "../services/authService";
import Form from "./common/form";
import Captcha from "./common/captcha";
import { toast } from "react-toastify";
import ResendButton from "./common/resendCode";
import logo from "../images/Logo.png";

class Login2 extends Form {
  state = {
    data: { code: "" },
    errors: {},
    errorCaptcha: "",
    buttonStatus: false,
  };

  schema = {
    code: Joi.label("Code"),
  };

  componentDidMount() {
    this.setState(
      this.buttonStatus(document.getElementById("subButton").disabled)
    );
    loadCaptchaEnginge(6, "white", "gray");
  }

  async componentDidUpdate(prevProps, prevState) {
    console.log(this.state.buttonStatus);
    console.log(document.getElementById("subButton").disabled);
    if (prevState.data.code !== this.state.data.code) {
      if (this.state.data.code.length === 4 && this.state.buttonStatus) {
        this.doSubmit();
      }
    }

    // if (prevState.buttonStatus !== this.state.buttonStatus) {
    //   loadCaptchaEnginge(6, "white", "gray");
    // }
  }

  buttonStatus = (status) => {
    this.setState({ buttonStatus: status });
    const myInput = document.getElementById("code");
    if (!status) {
      const data = { code: "" };
      this.setState({ data });
      myInput.disabled = true;
    } else {
      myInput.disabled = false;
    }
  };

  handelResendCode = async () => {
    try {
      const body = {
        phoneNumber: this.props.phoneNumber,
        password: this.props.password,
      };
      await auth.login(body);
    } catch (ex) {
      if (ex["response"]["status"] === 400) {
        console.log(ex);
      }
    }
  };

  checkCaptcha = () => {
    let user_captcha = document.getElementById("user_captcha_input").value;

    //check captcha
    if (validateCaptcha(user_captcha) === true) {
      this.setState({ errorCaptcha: "" });
      document.getElementById("user_captcha_input").value = "";
    } else {
      this.setState({ errorCaptcha: "Captcha Does Not Match" });
      document.getElementById("user_captcha_input").value = "";
    }

    return validateCaptcha(user_captcha) ? true : false;
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const body = {
        phoneNumber: this.props.phoneNumber,
        code: data.code,
      };
      await auth.verifylogin(body);

      window.location = "/profile";
    } catch (ex) {
      if (ex["response"]["status"] === 400) {
        toast.error("Code is not correct");
      }
    }
  };

  render() {
    return (
      <div className="d-flex align-items-stretch">
        <div className="row" style={{ flex: "1" }}>
          <div
            className="col-12 col-lg-6 d-none d-lg-block"
            style={{
              backgroundColor: "#46196D",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
          <div
            className="col-12 col-lg-6 desktopResponsive"
            style={{
              paddingTop: "24px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              className="d-none d-lg-block mx-auto"
              style={{
                marginTop: "62px",
                marginBottom: "56px",
                height: "64px",
              }}
            />
            <h1
              className="mx-auto d-lg-none"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                width: "296px",
                paddingBottom: "24px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Distributed Energy Management System
            </h1>
            <h1
              className="d-none d-lg-block"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                paddingBottom: "108px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Login
            </h1>
            <form
              style={{
                fontFamily: "iranyekanwebregular",
              }}
              onSubmit={this.handelSubmit}
            >
              {this.renderInput(
                "code",
                `Please enter the code which has been sent to phone number ${this.props.phoneNumber}`,
                "Enter code"
              )}
              <div
                style={{
                  marginTop: "24px",
                  paddingBottom: "32px",
                }}
              >
                <p
                  className="text-purple-950"
                  style={{ cursor: "pointer", fontWeight: "500" }}
                  onClick={() => (window.location = "/login")}
                >
                  Change phone number
                </p>
              </div>

              <div style={{ marginTop: "24px", marginBottom: "32px" }}>
                {!this.state.buttonStatus && (
                  <Captcha error={this.state.errorCaptcha} />
                )}
              </div>
            </form>
            <div className="resendCodeButton">
              <ResendButton
                buttonStatus={this.buttonStatus}
                handelResendCode={this.handelResendCode}
                checkCaptcha={this.checkCaptcha}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login2;
