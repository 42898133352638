import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./components/forgotPassword";
import Home from "./components/home";
import Login from "./components/login";
import NotFound from "./components/notFound";
import Profile from "./components/profile";
import Register from "./components/register";
import SubmitRequest from "./components/submitRequest";
import ProposalConfirm from "./components/proposalConfirm";
import TransferToken from "./components/transferToken";
import "react-toastify/dist/ReactToastify.css";

document.body.style = "background: #f8f8f8";

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <main>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/information_confirmation/:id" element={<ProposalConfirm/>}/>
            <Route path="/submit_request" element={<SubmitRequest />} />
            <Route path="/transfer-token" element={<TransferToken />} />
            <Route path="/notFound" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/notFound" />} />
          </Routes>
        </BrowserRouter>
      </main>
    </React.Fragment>
  );
}

export default App;
