import React, { useEffect, useState } from "react";
import auth from "../services/authService";
import Table from "./common/table";

function AdminProfileTable(props) {
  const [proposals, setProposals] = useState([]);

  const columns = [
    {
      key: "info",
      content: (user) => (
        <div style={{ marginLeft: "24px", marginTop: "24px" }}>
          <p
            style={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "12px",
              alignItems: "center",
              textTransform: "capitalize",
              marginBottom: "8px",

              color: "#2D3436",
            }}
          >
            {user.nationalId}
          </p>
          <p
            style={{
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "12px",
              textTransform: "capitalize",
              marginBottom: "24px",

              color: "#B2BEC3",
            }}
          >
            {user.meter_number}
          </p>
        </div>
      ),
    },

    {
      key: "isComfirm",
      content: (user) => (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "28px",
            marginRight: "24px",
          }}
        >
          {user.status === 0 && (
            <span className="badge rounded-pill text-bg-warning my-auto">
              Pending
            </span>
          )}
          {user.status === 1 && (
            <span className="badge rounded-pill text-bg-success my-auto">
              Confirmation
            </span>
          )}
          {user.status === 2 && (
            <span className="badge rounded-pill text-bg-danger my-auto">
              Rejection
            </span>
          )}
        </div>
      ),
    },
  ];
  useEffect(() => {
    async function getUserInformation() {
      const result = await auth.getAllProposals();
      setProposals(result["data"]);
    }

    getUserInformation();
  }, []);

  return (
    <div>
      <Table
        columns={columns}
        data={proposals}
        linkDestination="information_confirmation"
      />
    </div>
  );
}

export default AdminProfileTable;
