import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import TableBodyLinked from "./tableBodyLinked";

const Table = ({ columns, data, linkDestination, style = "table" }) => {
  return (
    <table className={style}>
      <TableHeader columns={columns} />
      {linkDestination ? (
        <TableBodyLinked
          data={data}
          columns={columns}
          linkDestination={linkDestination}
        />
      ) : (
        <TableBody data={data} columns={columns} />
      )}
    </table>
  );
};

export default Table;
