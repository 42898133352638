import React from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import Joi from "joi-browser";
import auth from "../services/authService";
import Form from "./common/form";
import Captcha from "./common/captcha";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import logo from "../images/Logo.png";

class Login1 extends Form {
  state = {
    data: { phoneNumber: "", password: "" },
    errors: {},
    errorCaptcha: "",
  };

  schema = {
    phoneNumber: Joi.string()
      .required()
      .min(10)
      .max(14)
      .regex(/^[0-9]/)
      .label("Phone Number"),
    password: Joi.string().required().label("Password"),
  };

  componentDidMount() {
    loadCaptchaEnginge(6, "white", "gray");
  }

  doSubmit = async () => {
    let user_captcha = document.getElementById("user_captcha_input").value;

    //check captcha
    if (validateCaptcha(user_captcha) === true) {
      this.setState({ errorCaptcha: "" });
      document.getElementById("user_captcha_input").value = "";
    } else {
      this.setState({ errorCaptcha: "Captcha Does Not Match" });
      document.getElementById("user_captcha_input").value = "";
    }

    if (validateCaptcha(user_captcha) === true) {
      try {
        const { data } = this.state;
        const body = {
          phoneNumber: data.phoneNumber,
          password: data.password,
        };
        const result = await auth.login(body);

        this.props.changeLoginPage(2, data.phoneNumber, data.password);
      } catch (ex) {
        if (ex["response"]["status"] === 400) {
          toast.error("Phone Number or Password is not correct");
        }
      }
    }
  };

  render() {
    return (
      <div className="d-flex align-items-stretch">
        <div className="row" style={{ flex: "1" }}>
          <div
            className="col-12 col-lg-6 d-none d-lg-block"
            style={{
              backgroundColor: "#46196D",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
          <div
            className="col-12 col-lg-6 desktopResponsive"
            style={{
              paddingTop: "24px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              className="d-none d-lg-block mx-auto"
              style={{
                marginTop: "62px",
                marginBottom: "56px",
                height: "64px",
              }}
            />
            <h1
              className="mx-auto d-lg-none"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                width: "296px",
                paddingBottom: "24px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Distributed Energy Management System
            </h1>
            <h1
              className="d-none d-lg-block"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                paddingBottom: "108px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Login
            </h1>
            <form
              style={{
                fontFamily: "iranyekanwebregular",
              }}
              onSubmit={this.handelSubmit}
            >
              {this.renderInput(
                "phoneNumber",
                "Username",
                "Enter your phone number"
              )}
              {this.renderInput("password", "Password", "", "", "password")}
              <div style={{ marginTop: "24px", marginBottom: "32px" }}>
                <Captcha error={this.state.errorCaptcha} />
              </div>
              <div>{this.renderButton("login")}</div>
            </form>
            <Link to="/forgot_password" style={{ textDecoration: "none" }}>
              <div style={{ textAlign: "center" }}>
                <p
                  className="text-purple-950"
                  style={{ marginTop: "24px", fontWeight: "500" }}
                >
                  forgot password?
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Login1;
