import React from "react";

function InformationShow({ label, value }) {
  return (
    <div>
      <label
        style={{
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "16px",
          alignItems: "center",
          marginBottom: "16px",
          color: "#7B7B7B ",
        }}
      >
        {label}
      </label>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px 12px",
          width: "100%",
          height: "48px",
          border: "1px solid #DFE6E9",
          borderRadius: "7px",
        }}
      >
        {value}
      </div>
    </div>
  );
}

export default InformationShow;
