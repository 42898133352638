import React from "react";

export function doorIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5556 4.26841H18.2222C18.6937 4.26841 19.1459 4.47205 19.4793 4.83454C19.8127 5.19703 20 5.68867 20 6.2013V7.16775M15.5556 19.7316H18.2222C18.6937 19.7316 19.1459 19.5279 19.4793 19.1655C19.8127 18.803 20 18.3113 20 17.7987V16.8322M9.33422 12H9.33333M15.5556 12H20M20 12L18.2222 10.0671M20 12L18.2222 13.9329M5.26667 19.1788L10.6 20.9184C10.8655 21.005 11.1459 21.023 11.419 20.9708C11.692 20.9186 11.95 20.7977 12.1724 20.6178C12.3948 20.4379 12.5754 20.2039 12.6998 19.9346C12.8242 19.6653 12.8889 19.3681 12.8889 19.0667V4.93332C12.8889 4.63194 12.8242 4.33471 12.6998 4.06539C12.5754 3.79606 12.3948 3.5621 12.1724 3.38218C11.95 3.20227 11.692 3.08139 11.419 3.0292C11.1459 2.97701 10.8655 2.99496 10.6 3.08161L5.26667 4.82121C4.90046 4.94073 4.57946 5.18543 4.35128 5.51899C4.1231 5.85256 3.99992 6.25722 4 6.67293V17.3271C3.99992 17.7428 4.1231 18.1474 4.35128 18.481C4.57946 18.8146 4.90046 19.0593 5.26667 19.1788Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function personIcon() {
  return (
    <svg
      className="mx-auto"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#D9D9D9" />
      <path
        d="M32 5.33334C17.272 5.33334 5.33334 17.272 5.33334 32C5.33334 46.728 17.272 58.6667 32 58.6667C46.728 58.6667 58.6667 46.728 58.6667 32C58.6667 17.272 46.728 5.33334 32 5.33334Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3893 48.9227C11.3893 48.9227 17.3333 41.3333 32 41.3333C46.6667 41.3333 52.6133 48.9227 52.6133 48.9227M32 32C34.1217 32 36.1566 31.1571 37.6569 29.6569C39.1571 28.1566 40 26.1217 40 24C40 21.8783 39.1571 19.8434 37.6569 18.3431C36.1566 16.8429 34.1217 16 32 16C29.8783 16 27.8434 16.8429 26.3431 18.3431C24.8429 19.8434 24 21.8783 24 24C24 26.1217 24.8429 28.1566 26.3431 29.6569C27.8434 31.1571 29.8783 32 32 32Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function plusIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14286 14V8.85714H2V7.14286H7.14286V2H8.85714V7.14286H14V8.85714H8.85714V14H7.14286Z"
        fill="white"
      />
    </svg>
  );
}

export function closeIcon() {
  return (
    <svg
      color="#FFFFFF"
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="currentColor"
      className="bi bi-x-circle"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
}

export function checkIcon(color = "currentColor", size = "60") {
  return (
    <svg
      color="#FFFFFF"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className="bi bi-check-circle mx-auto"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
    </svg>
  );
}

export function crossIcon() {
  return (
    <svg
      color="#FFFFFF"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      className="bi bi-x-lg"
      viewBox="0 0 16 16"
    >
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
    </svg>
  );
}

export function dashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#130F26"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.079 16.136h5.815"
      ></path>
      <path
        stroke="#130F26"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.4 13.713c0-5.631.614-5.238 3.919-8.303C7.765 4.246 10.015 2 11.958 2c1.942 0 4.237 2.235 5.696 3.41 3.305 3.065 3.918 2.672 3.918 8.303C21.572 22 19.613 22 11.986 22 4.359 22 2.4 22 2.4 13.713z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function requestsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g clipPath="url(#clip0_534_19594)">
        <path
          fill="#46196D"
          d="M3.5 3h18a1 1 0 011 1v16a1 1 0 01-1 1h-18a1 1 0 01-1-1V4a1 1 0 011-1zm17 4.238l-7.928 7.1L4.5 7.216V19h16V7.238zM5.011 5l7.55 6.662L20.002 5H5.011z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_534_19594">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export function downArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      fill="none"
      viewBox="0 0 14 8"
    >
      <path
        fill="#636E72"
        d="M7 5.172l4.95-4.95 1.414 1.414L7 8 .636 1.636 2.05.222 7 5.172z"
      ></path>
    </svg>
  );
}

export function searchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_350_5701)">
        <path
          fill="#636E72"
          d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0111 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 01-1.969 5.617zm-2.006-.742A6.977 6.977 0 0018 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 004.875-1.975l.15-.15z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_350_5701">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export function infoIcon(active) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fillRule="evenodd"
        stroke={active ? "#46196D" : "#2D3436"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.896 14.94c-2.46 0-4.563-.382-4.563-1.916 0-1.534 2.089-2.95 4.563-2.95 2.462 0 4.563 1.402 4.563 2.936s-2.088 1.93-4.563 1.93zM7.892 7.949a2.924 2.924 0 10-2.925-2.925A2.914 2.914 0 007.87 7.95h.02z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function powerIcon(active) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke={active ? "#46196D" : "#2D3436"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.906 2.9v1.77M8.906 12.195v1.48M8.906 10.196V6.67"
      ></path>
      <path
        fillRule="evenodd"
        stroke={active ? "#46196D" : "#2D3436"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.333 9.867c-1.793 0-1.793-2.735 0-2.735 0-3.168 0-4.299-6.333-4.299s-6.333 1.13-6.333 4.3c1.794 0 1.794 2.734 0 2.734 0 3.168 0 4.3 6.333 4.3s6.333-1.131 6.333-4.3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function locationIcon(active) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke={active ? "#46196D" : "#2D3436"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.052 11.256H9.93"
      ></path>
      <path
        fillRule="evenodd"
        stroke={active ? "#46196D" : "#2D3436"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.6 9.642c0-3.754.41-3.492 2.613-5.536.964-.776 2.464-2.273 3.76-2.273 1.294 0 2.824 1.49 3.796 2.273 2.204 2.044 2.612 1.782 2.612 5.536 0 5.524-1.306 5.524-6.39 5.524-5.085 0-6.39 0-6.39-5.524z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default {
  doorIcon,
  personIcon,
  plusIcon,
  closeIcon,
  checkIcon,
  crossIcon,
  dashboardIcon,
  requestsIcon,
  downArrowIcon,
  searchIcon,
  infoIcon,
  locationIcon,
  powerIcon,
};
