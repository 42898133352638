import React, { useState } from "react";
import ForgotPassword1 from "./forgotPassword1";
import ForgotPassword2 from "./forgotPassword2";
import ForgotPassword3 from "./forgotPassword3";

function ForgotPassword(props) {
  const [forgotPassword, setForgotPassword] = useState(1);

  return (
    <div>
      {forgotPassword === 1 && <ForgotPassword1 />}
      {forgotPassword === 2 && <ForgotPassword2 />}
      {forgotPassword === 3 && <ForgotPassword3 />}
    </div>
  );
}

export default ForgotPassword;
