import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import auth from "../services/authService";
import AdminProfile from "./adminProfile";
import UserProfile from "./userProfile";

function Profile(props) {
  const [user, setUser] = useState("");

  useEffect(() => {
    async function getUserInformation() {
      const result = auth.getCurrentUser();
      setUser(result["is_super"]);
    }

    getUserInformation();
  }, []);

  if (!auth.getCurrentUser()) return <Navigate to="/home" />;
  return (
    <div>
      {user && <AdminProfile />}
      {!user && <UserProfile />}
    </div>
  );
}

export default Profile;
