import React, { useState, useEffect } from "react";
import "../.././App.css";
import icons from "../common/icons";
import auth from "../../services/authService";

function ProfileHeader(props) {
  const [nationalId, setNationalId] = useState("");
  const [walletId, setWalletId] = useState("");

  useEffect(() => {
    async function getUserInformation() {
      const result = auth.getCurrentUser();
      setNationalId(result["national_id"]);
      setWalletId(result["wallet_id"]);
    }

    getUserInformation();
  });

  const copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  return (
    <div
      style={{
        paddingTop: "16px",
        fontFamily: "iranyekanwebregular",
      }}
    >
      <div
        style={{ position: "absolute", marginLeft: "16px", cursor: "pointer" }}
        onClick={() => (auth.logout(), (window.location = "/home"))}
      >
        {icons.doorIcon()}
      </div>
      <div style={{ textAlign: "center" }}>
        <div>{icons.personIcon()}</div>
        <p style={{ paddingTop: "16px" }}>{nationalId}</p>
        <div style={{}}>
          <div
            value={walletId}
            id="walletId"
            className="mx-auto"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              padding: "8px 4px",
              width: "40%",
              height: "36px",
              border: "1px solid #DFE6E9",
              borderRadius: "7px",
              backgroundColor: "white",
              overflow: "hidden",
            }}
          >
            {walletId}
          </div>
          <button
            className="bg-purple-950 hover:bg-purple-900 text-white py-1 px-3 border border-purple-950 rounded"
            style={{ marginTop: "8px" }}
            onClick={() => {
              copyToClipboard(document.getElementById("walletId").getAttribute("value"))
            }}
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
