import http from "./httpService";
import jwtDecode from "jwt-decode";

const apiEndpoint1 = "registercheck";
const apiEndpoint2 = "register";
const apiEndpoint3 = "login";
const apiEndpoint4 = "verifylogin";
const apiEndpoint5 = "user/purposeresidential";
const apiEndpoint6 = "user/getallproposals";
const apiEndpoint7 = "user/acceptporposal";
const apiEndpoint8 = "user/rejectproposal";
const apiEndpoint9 = "user/balance";
const apiEndpoint10 = "user/transfer";

const tokenKey = "token";

export async function registercheck(body) {
  const { data } = await http.post(apiEndpoint1, body, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export async function register(body) {
  const { data } = await http.post(apiEndpoint2, body, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export async function login(body) {
  const { data } = await http.post(apiEndpoint3, body, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export async function verifylogin(body) {
  const { data } = await http.post(apiEndpoint4, body, {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  localStorage.setItem(tokenKey, data["token"])
  return data;
}

export async function SubmitRequest(body) {
  const { data } = await http.post(apiEndpoint5, body, {
    headers: {
      authorization: "Token: " + localStorage.getItem(tokenKey),
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export async function getAllProposals() {
  const { data } = await http.get(apiEndpoint6, {
    headers: {
      authorization: "Token: " + localStorage.getItem(tokenKey),
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export async function acceptPorposal(body) {
  const { data } = await http.post(apiEndpoint7,body, {
    headers: {
      authorization: "Token: " + localStorage.getItem(tokenKey),
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export async function rejectPorposal(body) {
  const { data } = await http.post(apiEndpoint8,body, {
    headers: {
      authorization: "Token: " + localStorage.getItem(tokenKey),
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export async function getUserBalance() {
  const { data } = await http.get(apiEndpoint9, {
    headers: {
      authorization: "Token: " + localStorage.getItem(tokenKey),
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export async function transferToken(body) {
  const { data } = await http.post(apiEndpoint10,body, {
    headers: {
      authorization: "Token: " + localStorage.getItem(tokenKey),
      "Content-Type": "application/json; charset=UTF-8",
    },
  });
  return data;
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export default {
  registercheck,
  register,
  login,
  verifylogin,
  getAllProposals,
  SubmitRequest,
  acceptPorposal,
  rejectPorposal,
  getCurrentUser,
  getUserBalance,
  transferToken,
  logout,
};
