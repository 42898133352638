import React from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import Joi from "joi-browser";
import Form from "./common/form";
import Captcha from "./common/captcha";
import logo from "../images/Logo.png";

class ForgotPassword3 extends Form {
  state = {
    data: { password: "", repeat_password: "" },
    errors: {},
    errorCaptcha: "",
  };

  schema = {
    password: Joi.string().min(3).max(15).required().label("Password"),
    repeat_password: Joi.any()
      .valid(Joi.ref("password"))
      .required()
      .options({ language: { any: { allowOnly: "must match password" } } })
      .label("Repeat Password"),
  };

  componentDidMount() {
    loadCaptchaEnginge(6, "white", "gray");
  }

  doSubmit = async () => {};

  render() {
    return (
      <div className="d-flex align-items-stretch">
        <div className="row" style={{ flex: "1" }}>
          <div
            className="col-12 col-lg-6 d-none d-lg-block"
            style={{
              backgroundColor: "#46196D",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
          <div
            className="col-12 col-lg-6 desktopResponsive"
            style={{
              paddingTop: "24px",
            }}
          >
            <img
              src={logo}
              alt="logo"
              className="d-none d-lg-block mx-auto"
              style={{
                marginTop: "62px",
                marginBottom: "56px",
                height: "64px",
              }}
            />
            <h1
              className="mx-auto d-lg-none"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                width: "296px",
                paddingBottom: "24px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Distributed Energy Management System
            </h1>
            <h1
              className="d-none d-lg-block"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "42px",
                paddingBottom: "108px",
                fontFamily: "iranyekanwebbold",
              }}
            >
              Recover password
            </h1>
            <form
              style={{
                fontFamily: "iranyekanwebregular",
              }}
              onSubmit={this.handelSubmit}
            >
              {this.renderInput(
                "password",
                "New Password",
                "Password must be at least 8 characters.",
                "",
                "password"
              )}
              {this.renderInput(
                "repeat_password",
                "Repeat password",
                "Enter password again.",
                "",
                "password"
              )}
              <div style={{ marginTop: "24px", marginBottom: "32px" }}>
                <Captcha />
              </div>
              <div>{this.renderButton("next")}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword3;
